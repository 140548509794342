import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { MDBCard, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import useWindowDimensions from "../../../components/common/window-dimension";
import { useNavigate } from "react-router-dom";

export default function HandlingExperts({ data, selection, serviceId, setSelect }) {
    const navigate = useNavigate();
    const { width } = useWindowDimensions();
    const [ currentIndex, setCurrentIndex ] = useState(1);

    const handleSelectExpert = (expertId) => {
      setSelect(serviceId, expertId)
    }

    return (
        <div className="our-expert">
          <Swiper
            slidesPerView={width > 700 ? 3.3 : width > 550 ? 3.2 : 1.8}
            spaceBetween={0}
            grabCursor={true}
            navigation={
              data.store_img && data.store_img.length > 1 ? true : false
            }
            onSlideChange={(s) => setCurrentIndex(s.activeIndex + 1)}
            modules={[Navigation]}
          >
            {data && data.length > 0 &&
              data.map((expert, expertIndex) => (
              <SwiperSlide key={expertIndex}>
                <div className="expert-swiper" onClick={() => handleSelectExpert(expert.expert_id)}>
                  <div className={`expert-frame ${expert.expert_id === selection ? '' : 'default'} pointer`}>
                      <LazyLoadImage
                          alt="store-img"
                          src={expert.expert_img}
                      />
                  </div>
                  <div className="expert-basic d-grid ">
                    <label className="mt-1 text-center _label --expert_name pointer">{expert.expert_name}</label>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
    )
}