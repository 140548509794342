import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { MDBCard, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";

export default function StoreInfo({ data }) {

    const [currentIndex, setCurrentIndex] = useState(1);
    return (
        <div className="store-info">
          <Swiper
            slidesPerView={1.1}
            spaceBetween={0}
            grabCursor={true}
            navigation={
              data.store_img && data.store_img.length > 1 ? true : false
            }
            onSlideChange={(s) => setCurrentIndex(s.activeIndex + 1)}
            modules={[Navigation]}
          >
            {data.store_img &&
              data.store_img.length > 0 &&
              data.store_img.map((img, imgIndex) => (
              <SwiperSlide key={imgIndex}>
                  <div className="store-frame">
                      <LazyLoadImage
                          alt="store-img"
                          src={img}
                      />
                  </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <MDBCard className="store-card mt-3">
            <div className="flex justify-between items-center mb-2">
              <label className="flex items-center _label">
                <Icon icon="uim:calender" color="#8c91e5" width={24} height={24} />
                <span className="ms-2">Operations Hours</span>
                <span className="ms-2">{data.open_time} - {data.close_time}</span>
              </label>
              <a className="location-icon pointer" href={data.location} target="blank">
                <Icon icon="ion:location" color="#9f9f9f" width={24} height={24} />
              </a>
            </div>
            <hr className="m-0" />
            <div className="mt-2">
              {data.achievement && data.achievement.length > 0 && data.achievement.map((achievement, achievementIndex) => (
                <div style={{ marginBottom: '0.8em' }}>
                  <Icon icon="game-icons:achievement" color="#8c91e5" width={25} height={25} />
                  <label className="ms-2 _label --achievement">{achievement}</label>
                </div>
              ))}
            </div>
          </MDBCard>
        </div>
    )
}