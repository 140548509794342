import { MDBBtn, MDBCol, MDBRow } from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";
import Button from "../element/button";

export default function MainNavBar() {
  const navigate = useNavigate();

  return (
    <nav className="fixed-top main-navbar">
      <MDBRow className="mt-4">
        <MDBCol className="col-9">
        </MDBCol>
        <MDBCol className="col-3 flex gap-4 justify-content-center">
            <Button variant="outline" name="Open in Mobile" />
            <Button variant="outline" name ="Login" />
        </MDBCol>
      </MDBRow>
    </nav>
  );
}
