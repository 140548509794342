import { createSlice } from "@reduxjs/toolkit";

export const initialData = {
};

const dataSlice = createSlice({
  name: "setting",
  initialState: initialData,
  reducers: {
  },
  extraReducers: {
  },
});

export const { setToken} = dataSlice.actions;

export default dataSlice.reducer;

