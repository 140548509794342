import { useState } from "react";

//lib
import { Field, useFormikContext } from "formik";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";

export default function Text({
  label,
  type,
  name,
  disabled,
  placeholder,
  verification,
  className,
  handleVerification,
  as,
  editData,
  value,
  setErrorStatus,
  autoFocus,
  inputMode,
  maxLength,
  onChange,
}) {
  const { values, errors, setFieldValue } = useFormikContext();

  const [inputFocus, setInputFocus] = useState(false);

  const handleFocus = () => {
    setInputFocus(true);
  };
  const handleBlur = () => {
    setInputFocus(false);
  };

  return (
    <MDBCol
      className={as ? `element _text ${className}` : `element _text`}
      style={inputFocus ? { border: "0.15rem solid #8C91E5" } : {}}
    >
      {label && (
        <MDBRow>
          <label className="element _label">{label}</label>
        </MDBRow>
      )}
      <MDBRow
        className={`element _text ${
          errors[name] && !as ? "_errors" : "_errors2"
        }`}
      >
        {editData && as ? (
          <Field
            onFocus={handleFocus}
            onBlur={handleBlur}
            type={type ? type : "text"}
            name={name}
            label={placeholder}
            disabled={disabled}
            as={as}
            value={value}
            onChange={(e) => {
              editData(e.target.value);
              setErrorStatus(true);
            }}
            innerRef={autoFocus}
            inputMode={inputMode ? inputMode : ""}
            maxLength={maxLength ? maxLength : ''}
          />
        ) : as ? (
          <Field
            onFocus={handleFocus}
            onBlur={handleBlur}
            type={type ? type : "text"}
            name={name}
            label={placeholder}
            disabled={disabled}
            className={"focus"}
            as={as}
            innerRef={autoFocus}
            inputMode={inputMode ? inputMode : ""}
            maxLength={maxLength ? maxLength : ''}
            onChange={onChange ? onChange : ''} //
          />
        ) : (
          <Field
            type={type ? type : "text"}
            name={name}
            placeholder={placeholder}
            disabled={disabled}
            className={`${className}`}
            innerRef={autoFocus}
            inputMode={inputMode ? inputMode : ""}
            maxLength={maxLength ? maxLength : ''}
          />
        )}
        {verification && (
          <div className="verification-button">
            <button type="button" onClick={() => handleVerification()}>
              SEND
            </button>
          </div>
        )}
      </MDBRow>
      {!as && errors && errors[name] ? (
        <div
          className="text-danger text-right no-padding error-message"
          style={{ fontSize: "13px" }}
        >
          {errors[name]}
        </div>
      ) : null}
    </MDBCol>
  );
}
