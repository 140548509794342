import { Icon } from "@iconify/react";

export default function NewSection(props) {
    const { className, icon, color } = props;
    
    return (
        <div className={`element _new-section flex items-center gap-2 ${className}`}>
            {icon ?
                <Icon icon={icon} color={color} width={25} />
            :
                <div className="purple-div" />
            }
            <div className="w-100">{props.children}</div>
        </div>
    )
}