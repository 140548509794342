import useWindowDimensions from "../common/window-dimension";
import MainHeader from "../header/main-header";
import MainNavBar from "../navbar/main-navbar";

export default function MainLayout(props) {
    const { width } = useWindowDimensions(); 

    return (
        <div>
            {width > 991 && <MainNavBar />}
            <div className="content-container">{props.children}</div>
        </div>
    )
}
