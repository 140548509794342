import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { MDBCard, MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import useWindowDimensions from "../../../components/common/window-dimension";
import NewSection from "../../../components/element/new.section";
import OurService from "../../Service/component/our.service";
import ServiceDetails from "./service.details";

export default function ServiceInfo({ info, days, service }) {
    const { width } = useWindowDimensions();

    return (
        <div className="service-info">
            <div className="service-frame">
                <LazyLoadImage
                    alt="service-img"
                    src={info.service_img}
                />
            </div>
            <div className="info-row gap-2 mt-3">
                <label>{info.service_name}</label>
                <div className="tag-row mt-2">
                    {info.service_tag && info.service_tag.length > 0 && info.service_tag.map((tag, tagIndex) => (
                        <div className="service-tag" key={tagIndex}>
                            {tag}
                        </div>
                    ))}
               </div>
                <MDBRow className="mt-4">
                    <MDBCol className={`text-center ${width < 991 ? 'ps-1 pe-1' : '' }`}>
                        <div className="flex items-center justify-center"> 
                            <Icon icon="material-symbols:price-check-rounded" color="#8c91e5" className="service-icon" />
                            <label className="_label --service_label ms-1">Service Price </label>
                        </div>
                        <div className="_label --service_value mt-1">
                            RM&nbsp;{info.service_price.toFixed(2)}
                        </div>
                    </MDBCol>
                    <MDBCol className={`text-center ${width < 991 ? 'ps-1 pe-1' : '' }`}>
                        <div className="flex items-center justify-center"> 
                            <Icon icon="ant-design:field-time-outlined" color="#8c91e5" className="service-icon" />
                            <label className="_label --service_label ms-1">Service Time </label>
                        </div>
                        <div className="_label --service_value mt-1">
                            {info.service_time}&nbsp;(mins)
                        </div>
                    </MDBCol>
                    <MDBCol className={`text-center ${width < 991 ? 'ps-1 pe-1' : '' }`}>
                        <div className="flex items-center justify-center"> 
                            <Icon icon="solar:star-bold-duotone" color="#8c91e5" className="service-icon" />
                            <label className="_label --service_label ms-1">Extra Service </label>
                        </div>
                        <div className="_label --service_value mt-1">
                            {info.extra_service}
                        </div>
                    </MDBCol>
                </MDBRow>
            </div>
        </div>
    )
}