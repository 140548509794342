import { useEffect, useRef, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { MDBBtn, MDBContainer, MDBModalBody } from "mdb-react-ui-kit";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/element/button";
import ScheduleBooking from "./components/schedule.booking";
import useWindowDimensions from "../../components/common/window-dimension";
import { closeBookingModal, openConfirmationModal, openStoreModal } from "../../redux/reducer/modalReducer";
import FullModal from "../../components/modal/full.modal.box";
import ModalHeader from "../../components/header/modal-header";
import { useNavigate } from "react-router-dom";
import Confirmation from "../Confirmation";
import { setBookingDetails, setTimeSelections } from "../../redux/reducer/bookingReducer";
import scrollToTop from "../../helpers/scroll-to-top";
import { toast } from "react-toastify";

export default function Booking({ totalPrice }) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const modalRef = useRef(null);

    const [ top, setTop ] = useState();
    const { width } = useWindowDimensions();
    
    const { isOpenBookingModal, isOpenConfirmationModal} = useSelector((state) => state.modal);
    
    const { timetable, expertSelections, timeSelections } = useSelector((state) => state.booking);
    const { store, expert, service, days, selected_services } = useSelector((state) => state.store);

    const [ isLastSlide, setIsLastSlide ] = useState(false);
    const [ nextSlide, setNextSlide ] = useState(false);

    const handleSubmit = () => {
        if ((selected_services.length !== Object.keys(expertSelections).length) 
        || (selected_services.length !== Object.keys(timeSelections).length)) {
            toast.error(`Select exactly ${selected_services.length} handling experts and booking time to proceed.`);
            return;
        }

        const formattedDetails = selected_services.map((serviceId) => {
            const selectedExpert = expert.find((e) => e.expert_id === expertSelections[serviceId]);
            const selectedTime = timeSelections[serviceId] || "";
            const matchingService = service.find((s) => s.service_id === serviceId);
      
            return {
                selected_service_name: matchingService.service_name,
                selected_service_price: matchingService.service_price,
                selected_service_image: matchingService.service_img,
                selected_expert_id: selectedExpert.expert_id,
                selected_expert_name: selectedExpert.expert_name,
                selected_booking_time: selectedTime,
            };
        });
        
        dispatch(setBookingDetails(formattedDetails))
        dispatch(closeBookingModal())
        navigate('/confirmation')
        dispatch(openConfirmationModal())

    };

    const backButton = () => {
        navigate('/')
        dispatch(closeBookingModal())
        dispatch(openStoreModal())
        dispatch(setTimeSelections({}))
    }

    useEffect(() => {
      setTop(true);
    }, [isOpenBookingModal]);
    
    useEffect(() => {
      if (top == true && modalRef.current) {
        scrollToTop(modalRef.current);
        setTop(false);
      }
    }, [top]);

    return (
        <>
            <FullModal
                centered
                scrollable
                staticBackdrop={true}
                show={isOpenBookingModal}
                // className={"share-modal-container"}
                backButton={backButton}
                screenSize={width >= 991 ? "xl" : "fullscreen-xl-down"}
                content={
                    <>
                        <ModalHeader title="Schedule Appointment" backButton={backButton} backButtonNoAnimation={backButton} />
                          <MDBModalBody ref={modalRef}>
                            <MDBContainer className="booking-container"> 
                                <ScheduleBooking expert={expert} date={timetable} setIsLastSlide={setIsLastSlide} setNextSlide={setNextSlide} nextSlide={nextSlide} />
                                <div className="button-line" />
                                <div className="mt-1 button-row"> 
                                    <div className="d-grid gap-2">
                                        <label  className="_label --selected_service" >{selected_services.length} services selected: </label>
                                        <label className="_label --main_price flex align-items-baseline gap-1"><span>RM</span> {totalPrice} </label>
                                    </div>
                                    <Button name={ isLastSlide ? 'Booking' : 'Next' } variant="solid" type={ isLastSlide ? 'submit' : 'button' } onClick={() => { isLastSlide ? handleSubmit() : setNextSlide(true)}} />
                                </div>
                            </MDBContainer>
                        </MDBModalBody>
                    </>
                }
            />
        </>
    );
}