import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { MDBCard, MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import useWindowDimensions from "../../../components/common/window-dimension";
import NewSection from "../../../components/element/new.section";
import OurService from "../../Service/component/our.service";

export default function ExpertInfo({ info, days, service }) {
    const { width } = useWindowDimensions();

    return (
        <div className="expert-info">
            <div className={`info-row flex ${ width > 991 ? 'items-center' : 'items-start' } gap-2`}>
                <div className="expert-frame col-3 col-md-2 col-lg-2">
                    <LazyLoadImage
                        alt="expert-img"
                        src={info.expert_img}
                    />
                </div>
                <div className="expert-basic">
                    <label className="_label --expert_name">{info.expert_name}</label>
                    <label className="_label --expert_desc mt-1">{info.expert_desc}</label>
                </div>
            </div>
            <MDBCard className="time-card mt-3">
                <MDBRow className="p-1 flex items-baseline">
                    <MDBCol className="_label --working_time col-3 text-center ps-1 pe-1">
                        Working Time:
                    </MDBCol>
                    {days && days.length > 0 && days.map((day, dayIndex) => (
                        <MDBCol className="days-col text-center ps-1 pe-1" style={{ borderRight: dayIndex !== days.length - 1 ? '0.1em solid #dfdfdf' : 'none' }}>
                            <label className={`_label --days ${day.status === 'inactive' ? 'inactive-days' : ''} `}>
                                {day.label}
                            </label>
                        </MDBCol>
                    ))}
                </MDBRow>
            </MDBCard>
        </div>
    )
}