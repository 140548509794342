import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { MDBCard, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import useWindowDimensions from "../../../components/common/window-dimension";
import { useNavigate } from "react-router-dom";
import { openExpertModal } from "../../../redux/reducer/modalReducer";
import { useDispatch } from "react-redux";
import { setSelectedExpert } from "../../../redux/reducer/storeReducer";

export default function OurExpert({ data }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { width } = useWindowDimensions();
    const [currentIndex, setCurrentIndex] = useState(1);

    const handleRedirect = (id) => {
      navigate('/expert')
      dispatch(openExpertModal())
      dispatch(setSelectedExpert(id))
    }

    function generateStars(rating) {
      const maxStars = 5;
      const stars = [];
    
      for (let i = 1; i <= maxStars; i++) {
        const starColor = i <= rating ? '#FFCB46' : '#d6d6d6';
        stars.push(<Icon icon="ic:baseline-star-rate" style={{ color: starColor }} key={i} />);
      }
    
      return stars;
    }

    return (
        <div className="our-expert">
          <Swiper
            slidesPerView={width > 700 ? 4 : width > 550 ? 3.2 : 1.9}
            spaceBetween={0}
            grabCursor={true}
            navigation={
              data.store_img && data.store_img.length > 1 ? true : false
            }
            onSlideChange={(s) => setCurrentIndex(s.activeIndex + 1)}
            modules={[Navigation]}
          >
            {data && data.length > 0 &&
              data.map((expert, expertIndex) => (
              <SwiperSlide key={expertIndex} className="store-slide">
                <div className="expert-swiper" onClick={() => handleRedirect(expert.expert_id)}>
                  <div className="expert-frame pointer">
                      <LazyLoadImage
                          alt="store-img"
                          src={expert.expert_img}
                      />
                  </div>
                  <div className="expert-basic d-grid pointer">
                    <label className="mt-1 mb-2 text-center _label --expert_name pointer">{expert.expert_name}</label>
                    <div>
                      <div className="_label --expert_skill flex">Experiences: &nbsp;
                        <div className="flex items-center">
                          {generateStars(expert.exp_rate)}
                        </div>
                      </div>
                      <div className="_label --expert_skill flex">Talkative: &nbsp;
                        <div className="flex items-center">
                          {generateStars(expert.talk_rate)}
                        </div>
                      </div>
                      <div className="_label --expert_skill flex">Skill: &nbsp;
                        <div className="flex items-center">
                          {generateStars(expert.skill_rate)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
    )
}