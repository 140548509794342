import { MDBContainer, MDBModalBody } from "mdb-react-ui-kit";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/element/button";
import ExpertInfo from "./component/expert.info";
import useWindowDimensions from "../../components/common/window-dimension";
import FullModal from "../../components/modal/full.modal.box";
import ModalHeader from "../../components/header/modal-header";
import { closeExpertModal, openBookingModal, openExpertModal, openStoreModal } from "../../redux/reducer/modalReducer";
import { useNavigate, useParams } from "react-router-dom";
import scrollToTop from "../../helpers/scroll-to-top";
import { useEffect, useRef, useState } from "react";
import OurService from "../Service/component/our.service";
import NewSection from "../../components/element/new.section";
import { setSelectedServices } from "../../redux/reducer/storeReducer";

export default function Expert() {
    const params = useParams()
    const modalRef = useRef(null)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    const { width } = useWindowDimensions();
    const [ top, setTop ] = useState();
    const { isOpenExpertModal } = useSelector((state) => state.modal);
    const { store, expert, service, days, selected_expert } = useSelector((state) => state.store);

    const [ chooseServices, setChooseServices ] = useState([]);

    const handleRedirect = () => {
        navigate('/booking')
        dispatch(closeExpertModal())
        dispatch(openBookingModal());
        dispatch(setSelectedServices(chooseServices))
    }

    const backButton = () => {
        navigate('/')
        dispatch(closeExpertModal())
        dispatch(openStoreModal())
    }

    useEffect(() => {
      setTop(true);
    }, [isOpenExpertModal]);
    
    useEffect(() => {
      if (top == true && modalRef.current) {
        scrollToTop(modalRef.current);
        setTop(false);
      }
    }, [top]);
    
    return (
        <>
            <FullModal
            centered
            scrollable
            staticBackdrop={true}
            show={isOpenExpertModal}
            backButton={backButton}
            screenSize={width >= 991 ? "xl" : "fullscreen-xl-down"}
            content={
                <>
                    <ModalHeader title="Expert Info" backButton={backButton} backButtonNoAnimation={backButton} />
                      <MDBModalBody ref={modalRef}>
                        <MDBContainer className="expert-container"> 
                            <ExpertInfo info={expert.find((e) => e.expert_id === selected_expert)} days={days} service={service} />
                            <NewSection>Handling Services</NewSection>
                            <OurService data={service} chooseServices={chooseServices} setChooseServices={setChooseServices} />
                            <div className="button-line" />
                            <div className="mt-1 button-row"> 
                                <div className="d-grid gap-2">
                                    <label  className="_label --selected_service" >2 services selected: </label>
                                    <label className="_label --main_price flex align-items-baseline gap-1"><span>RM</span> 100 </label>
                                </div>
                                <Button name="Booking" variant="solid" onClick={() => handleRedirect()}/>
                            </div>
                        </MDBContainer>
                    </MDBModalBody>
                </>
            }
            />
        </>
    )
}