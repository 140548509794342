import { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { MDBCard, MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import useWindowDimensions from "../../../components/common/window-dimension";
import { useNavigate } from "react-router-dom";

export default function AvailableTime({ data, selection, serviceId, setSelect }) {
    const navigate = useNavigate();
    const { width } = useWindowDimensions();

    const parsedSelection = selection ? selection.split(', ') : [];
    const selectedDateFromSelection = parsedSelection.length >= 2 ? parsedSelection[0].split(' ')[1] : null;
    const selectedTimeFromSelection = parsedSelection.length >= 2 ? parsedSelection[1] : null;

    const [selectedDate, setSelectedDate] = useState('Today 07-17')

    const [activeDate, setActiveDate] = useState(selection ? selectedDateFromSelection : '07-17')
    const [activeTime, setActiveTime] = useState(selection ? selectedTimeFromSelection : '11:00')
    
    const handleSelectTime = (time, date) => {
        setActiveTime(time)
        setSelect(serviceId, `${selectedDate}, ${time}`)
    }

    const handleSelectDate = (day, date) => {
        setActiveDate(date)
        setSelectedDate(`${day} ${date}`)
    }

    // useEffect(() => {
    //     setSelect(serviceId, `${selectedDate}, ${activeTime}`)
    // }, []) 

    return (
        <div className="available-time">
            <div className="date-swipper flex gap-4">
            {data?.length > 0 && data.map((timetable, timetableIndex) => (
                <div className={`date-block ${ timetable.date == activeDate ? '--active' : '' } text-center pointer`} onClick={() => handleSelectDate(timetable.day, timetable.date)}> 
                    <label className="pointer">{timetable.day}</label>
                    <br />
                    <label className="pointer">{timetable.date}</label>
                </div>
            ))}
            </div>
            <MDBRow className="time-row mt-3 gap-3">
            {activeDate && data.find((item) => item.date === activeDate).time.map((time) => (
                <div 
                    className={`time-block ${ `${time.label}` == activeTime ? '--active pointer' : time.status === 'inactive' ? '--disabled' : 'pointer' } `} 
                    onClick={() => {
                        if(time.status !== 'inactive') {
                            handleSelectTime(time.label, activeDate)
                        }
                    }}
                > 
                    <label className={time.status !== 'inactive' ? "pointer" : ''}>{time.label}</label>
                </div>
            ))}
            </MDBRow>
        </div>
    )
}

