import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";

import useWindowDimensions from "../common/window-dimension";
import { useDispatch } from "react-redux";
import { setShowModal } from "../../redux/reducer/modalReducer";

export default function ModalHeader({
  title,
  backButton,
  backButtonNoAnimation
}) {
  const dispatch = useDispatch()
  const { width } = useWindowDimensions();

  const handleCloseModalWithDelay = () => {
    dispatch(setShowModal(false))
    setTimeout(() => {
      backButton();
    }, 500);
  };

  return (
    <MDBContainer className="m-0 p-0">
      <div
        className="modal-header-container"
        style={{
          position: width >= 991 ? "sticky" : "fixed",
          // marginTop: width >= 991 ? "0em" : "-1em",
        }}
      >
        <MDBRow className="info-row">
          <MDBCol className="info-col col-3">
            {(backButton || backButtonNoAnimation) && (
              <Icon
                icon="tabler:arrow-back-up"
                onClick={() => { 
                  backButtonNoAnimation ? backButtonNoAnimation() : handleCloseModalWithDelay()
                }}
                className="back-icon"
                width={24}
                style={{ cursor: "pointer" }}
              />
            )}
          </MDBCol>
          <MDBCol className="info-col">
            <label style={{ fontSize: width >= 991 ? "18px" : "" }}>
              {title}
            </label>
          </MDBCol>
          <MDBCol className="info-col col-2">
            {/* {settingButton && (
              <Icon
                icon="solar:menu-dots-bold"
                onClick={() => settingButton()}
                className="setting-icon"
                width={24}
              />
            )}
            {infoButton && (
              <Icon
                icon="fluent:info-32-regular"
                onClick={() => infoButton()}
                className="setting-icon"
                width={24}
                color="#C5C5C5"
              />
            )} */}
          </MDBCol>
        </MDBRow>
      </div>
    </MDBContainer>
  );
}
