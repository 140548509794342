import { createSlice } from "@reduxjs/toolkit";

export const initialData = {
  timetable: [
    { day: 'Today',
      date: '07-17',
      time: [
        {label: '09:00', value: '09:00', status: 'inactive'},
        {label: '10:00', value: '10:00', status: 'inactive'},
        {label: '11:00', value: '11:00', status: 'active'},
        {label: '12:00', value: '12:00', status: 'active'},
        {label: '13:00', value: '13:00', status: 'active'},
        {label: '14:00', value: '14:00', status: 'active'},
        {label: '15:00', value: '15:00', status: 'active'},
        {label: '16:00', value: '16:00', status: 'active'},
        {label: '17:00', value: '17:00', status: 'active'},
        {label: '18:00', value: '18:00', status: 'active'},
      ]},
      { day: 'Tue',
        date: '07-18',
        time: [
          {label: '09:00', value: '09:00', status: 'inactive'},
          {label: '10:00', value: '10:00', status: 'inactive'},
          {label: '11:00', value: '11:00', status: 'active'},
          {label: '12:00', value: '12:00', status: 'active'},
          {label: '13:00', value: '13:00', status: 'active'},
          {label: '14:00', value: '14:00', status: 'active'},
          {label: '15:00', value: '15:00', status: 'active'},
          {label: '16:00', value: '16:00', status: 'active'},
          {label: '17:00', value: '17:00', status: 'active'},
          {label: '18:00', value: '18:00', status: 'active'},
      ]},
      { day: 'Wed',
        date: '07-19',
        time: [
          {label: '09:00', value: '09:00', status: 'inactive'},
          {label: '10:00', value: '10:00', status: 'inactive'},
          {label: '11:00', value: '11:00', status: 'active'},
          {label: '12:00', value: '12:00', status: 'active'},
          {label: '13:00', value: '13:00', status: 'active'},
          {label: '14:00', value: '14:00', status: 'active'},
          {label: '15:00', value: '15:00', status: 'active'},
          {label: '16:00', value: '16:00', status: 'active'},
          {label: '17:00', value: '17:00', status: 'active'},
          {label: '18:00', value: '18:00', status: 'active'},
      ]},
      { day: 'Thurs',
        date: '07-20',
        time: [
          {label: '09:00', value: '09:00', status: 'inactive'},
          {label: '10:00', value: '10:00', status: 'inactive'},
          {label: '11:00', value: '11:00', status: 'active'},
          {label: '12:00', value: '12:00', status: 'active'},
          {label: '13:00', value: '13:00', status: 'active'},
          {label: '14:00', value: '14:00', status: 'active'},
          {label: '15:00', value: '15:00', status: 'active'},
          {label: '16:00', value: '16:00', status: 'active'},
          {label: '17:00', value: '17:00', status: 'active'},
          {label: '18:00', value: '18:00', status: 'active'},
      ]},
      { day: 'Fri',
        date: '07-21',
        time: [
          {label: '09:00', value: '09:00', status: 'inactive'},
          {label: '10:00', value: '10:00', status: 'inactive'},
          {label: '11:00', value: '11:00', status: 'active'},
          {label: '12:00', value: '12:00', status: 'active'},
          {label: '13:00', value: '13:00', status: 'active'},
          {label: '14:00', value: '14:00', status: 'active'},
          {label: '15:00', value: '15:00', status: 'active'},
          {label: '16:00', value: '16:00', status: 'active'},
          {label: '17:00', value: '17:00', status: 'active'},
          {label: '18:00', value: '18:00', status: 'active'},
      ]},
      { day: 'Sat',
        date: '07-22',
        time: [
          {label: '09:00', value: '09:00', status: 'inactive'},
          {label: '10:00', value: '10:00', status: 'inactive'},
          {label: '11:00', value: '11:00', status: 'active'},
          {label: '12:00', value: '12:00', status: 'active'},
          {label: '13:00', value: '13:00', status: 'active'},
          {label: '14:00', value: '14:00', status: 'active'},
          {label: '15:00', value: '15:00', status: 'active'},
          {label: '16:00', value: '16:00', status: 'active'},
          {label: '17:00', value: '17:00', status: 'active'},
          {label: '18:00', value: '18:00', status: 'active'},
      ]},
      { day: 'Sun',
        date: '07-23',
        time: [
          {label: '09:00', value: '09:00', status: 'inactive'},
          {label: '10:00', value: '10:00', status: 'inactive'},
          {label: '11:00', value: '11:00', status: 'active'},
          {label: '12:00', value: '12:00', status: 'active'},
          {label: '13:00', value: '13:00', status: 'active'},
          {label: '14:00', value: '14:00', status: 'active'},
          {label: '15:00', value: '15:00', status: 'active'},
          {label: '16:00', value: '16:00', status: 'active'},
          {label: '17:00', value: '17:00', status: 'active'},
          {label: '18:00', value: '18:00', status: 'active'},
      ]},
  ],
  booking_details: {},
  phone_no: '',
  expertSelections: {},
  timeSelections: {},
  contact_details: {}
};

const dataSlice = createSlice({
  name: "booking",
  initialState: initialData,
  reducers: {
    setExpertSelections: (state, action) => {
      state.expertSelections = action.payload;
    },
    setTimeSelections: (state, action) => {
      state.timeSelections = action.payload;
    },
    setBookingDetails: (state, action) => {
      state.booking_details = action.payload;
    },
    setContactDetails: (state, action) => {
      state.contact_details = action.payload;
    },
    setPhoneNo: (state, action) => {
      state.phone_no = action.payload;
    },
  },
  extraReducers: {
  },
});

export const { setToken, setExpertSelections, setTimeSelections, setBookingDetails, setPhoneNo, setContactDetails } = dataSlice.actions;

export default dataSlice.reducer;
