import { createSlice } from "@reduxjs/toolkit";

export const initialData = {
  isOpenStoreModal: false,
  isOpenExpertModal: false,
  isOpenServiceModal: false,
  isOpenBookingModal: false,
  isOpenConfirmationModal: false,
  isOpenSuccessModal: false,
  isOpenLoginModal: false,
};

const dataSlice = createSlice({
  name: "modal",
  initialState: initialData,
  reducers: {
    openStoreModal: (state, action) => {
      state.isOpenStoreModal = true;
    },
    openExpertModal: (state, action) => {
      state.isOpenExpertModal = true;
    },
    openServiceModal: (state, action) => {
      state.isOpenServiceModal = true;
    },
    openBookingModal: (state, action) => {
      state.isOpenBookingModal = true;
    },
    openConfirmationModal: (state, action) => {
      state.isOpenConfirmationModal = true;
    },
    openSuccessModal: (state, action) => {
      state.isOpenSuccessModal = true;
    },
    openLoginModal: (state, action) => {
      state.isOpenLoginModal = true;
    },
    closeStoreModal: (state, action) => {
      state.isOpenStoreModal = false;
    },
    closeExpertModal: (state, action) => {
      state.isOpenExpertModal = false;
    },
    closeServiceModal: (state, action) => {
      state.isOpenServiceModal = false;
    },
    closeBookingModal: (state, action) => {
      state.isOpenBookingModal = false;
    },
    closeConfirmationModal: (state, action) => {
      state.isOpenConfirmationModal = false;
    },
    closeSuccessModal: (state, action) => {
      state.isOpenSuccessModal = false;
    },
    closeLoginModal: (state, action) => {
      state.isOpenLoginModal = false;
    },
    closeAllModal: (state, action) => {
      state.isOpenStoreModal = false;
      state.isOpenStoreModal = false;
      state.isOpenExpertModal = false;
      state.isOpenServiceModal = false;
      state.isOpenBookingModal = false;
      state.isOpenConfirmationModal = false;
      state.isOpenSuccessModal = false;
      state.isOpenLoginModal = false;
    },
    setAnimationModal: (state, action) => {
      state.isAnimationModal = action.payload;
    },
    setShowModal: (state, action) => {
      state.showModal = action.payload;
    },
  },
  extraReducers: {
  },
});

export const { setToken, openStoreModal, openExpertModal, openServiceModal, openBookingModal, openConfirmationModal, openSuccessModal, openLoginModal, closeStoreModal, closeExpertModal, closeServiceModal, closeBookingModal, closeConfirmationModal, closeSuccessModal, closeLoginModal,closeAllModal, setAnimationModal, setShowModal } = dataSlice.actions;

export default dataSlice.reducer;
