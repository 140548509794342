import { createSlice } from "@reduxjs/toolkit";
import { ExpertFive, ExpertFour, ExpertOne, ExpertThree, ExpertTwo, FixxSmLogo, ServiceDetailsOne, ServiceDetailsTwo, ServiceFive, ServiceFour, ServiceOne, ServiceThree, ServiceTwo, StoreOne, StoreThree, StoreTwo } from "../../assets/images";

export const initialData = {
  store: {
    store_name: 'HairBit Saloon',
    store_reg_no: 'D-1223133',
    store_logo: FixxSmLogo,
    store_img: [
      StoreOne, 
      StoreTwo, 
      StoreThree, 
      StoreOne, 
    ],
    store_desc: 'HairBit Salon offers beauty enthusiasts a chance for a style revamp with its array of hair services. Among the treatments available at the salon include hair cutting, hair treatments, hair colouring, and more.',
    store_category: 'Hair Saloon',
    open_time: '10:00',
    close_time: '19:00',
    achievement: [
      'Master Stylist of the Year 2019',
      'Cutting Edge Talent Award',
      'Rising Star Award Malaysia 2021'
    ],
    location: 'https://www.waze.com/live-map/directions/hinata-hair-and-beauty-salon-jalan-pandan-ria-1-johor-bahru?to=place.w.68026383.680067224.20136891'
  },
  expert: [
    {
      expert_id: 1,
      expert_name: 'Caris Ong',
      expert_img: ExpertOne,
      expert_desc: 'Inspired by the renowned Japanese drama Beautiful Life, Caris is determined to be one of the best stylists in this thriving industry, providing her best to each and every guest with her well-honed knowledge and skills.',
      expert_working_day: [ 'Mon', 'Tues', 'Thurs', 'Fri', 'Sat' ],
      expert_service: [
        {
          service_name: 'One-tone Color',
          service_img: ServiceOne,
          service_tag: [
            'Confidence Boosts',
            'Adding Dimension'
          ],
          service_price: 100,
        },
        {
          service_name: 'One-tone Color',
          service_img: ServiceOne,
          service_tag: [
            'Confidence Boosts',
            'Adding Dimension'
          ],
          service_price: 100,
        },
        {
          service_name: 'One-tone Color',
          service_img: ServiceOne,
          service_tag: [
            'Confidence Boosts',
            'Adding Dimension'
          ],
          service_price: 100,
        }
      ],
      exp_rate: 4,
      talk_rate: 5,
      skill_rate: 4
    },
    {
      expert_id: 2,
      expert_name: 'Jerry Hew',
      expert_img: ExpertTwo,
      expert_desc: 'An unexpected occasion has made Jerry irresistibly fall for the charisma of a hairstylist. The medley of beautiful individuals he meets every day and the smiles rewarded from every guest has driven his utmost passion in this thrilling fast-paced industry.',
      expert_working_day: [ 'Mon', 'Tues', 'Thurs', 'Fri', 'Sat' ],
      expert_service: [
        {
          service_name: 'One-tone Color',
          service_img: ServiceOne,
          service_tag: [
            'Confidence Boosts',
            'Adding Dimension'
          ],
          service_price: 100,
        },
        {
          service_name: 'One-tone Color',
          service_img: ServiceOne,
          service_tag: [
            'Confidence Boosts',
            'Adding Dimension'
          ],
          service_price: 100,
        },
        {
          service_name: 'One-tone Color',
          service_img: ServiceOne,
          service_tag: [
            'Confidence Boosts',
            'Adding Dimension'
          ],
          service_price: 100,
        }
      ],
      exp_rate: 3,
      talk_rate: 5,
      skill_rate: 2
    },
    {
      expert_id: 3,
      expert_name: 'Hikky',
      expert_img: ExpertThree,
      expert_desc: 'Her reputation is no stranger to many aspiring hair-trendsetters as her list of happy guests includes prominent models, celebrity bloggers, and social media influencers. She finds great joy in creative mix of colours and snazzy styling to suit any personality.',
      expert_working_day: [ 'Mon', 'Tues', 'Thurs', 'Fri', 'Sat' ],
      expert_service: [
        {
          service_name: 'One-tone Color',
          service_img: ServiceOne,
          service_tag: [
            'Confidence Boosts',
            'Adding Dimension'
          ],
          service_price: 100,
        },
        {
          service_name: 'One-tone Color',
          service_img: ServiceOne,
          service_tag: [
            'Confidence Boosts',
            'Adding Dimension'
          ],
          service_price: 100,
        },
        {
          service_name: 'One-tone Color',
          service_img: ServiceOne,
          service_tag: [
            'Confidence Boosts',
            'Adding Dimension'
          ],
          service_price: 100,
        }
      ],
      exp_rate: 2,
      talk_rate: 5,
      skill_rate: 5
    },
    {
      expert_id: 4,
      expert_name: 'Vicky Cheh',
      expert_img: ExpertFour,
      expert_desc: 'A courteous yet friendly city girl that aimed to become a professional stylist in this highly-driven hair and beauty industry. Vicky has been honing her skills patiently in the past 7 years and she has gained a long list of happy customers even during her assistant career. For her, nothing beats the satisfaction of seeing her guest enjoys the new look!',
      expert_working_day: [ 'Mon', 'Tues', 'Thurs', 'Fri', 'Sat' ],
      expert_service: [
        {
          service_name: 'One-tone Color',
          service_img: ServiceOne,
          service_tag: [
            'Confidence Boosts',
            'Adding Dimension'
          ],
          service_price: 100,
        },
        {
          service_name: 'One-tone Color',
          service_img: ServiceOne,
          service_tag: [
            'Confidence Boosts',
            'Adding Dimension'
          ],
          service_price: 100,
        },
        {
          service_name: 'One-tone Color',
          service_img: ServiceOne,
          service_tag: [
            'Confidence Boosts',
            'Adding Dimension'
          ],
          service_price: 100,
        }
      ],
      exp_rate: 4,
      talk_rate: 5,
      skill_rate: 5
    },
    {
      expert_id: 5,
      expert_name: 'Wai Hou',
      expert_img: ExpertFive,
      expert_desc: 'Wai Hou is a dedicated and creative hair stylist with years of experience in the industry. His passion for hairstyling goes beyond the technical aspects; it’s about making people feel confident and beautiful. He believes that the perfect hairstyle can leave a lasting impression. You can trust that your well-being is a priority when you choose Wai Hou as your hairstylist.',
      expert_working_day: [ 'Mon', 'Tues', 'Thurs', 'Fri', 'Sat' ],
      expert_service: [
        {
          service_name: 'One-tone Color',
          service_img: ServiceOne,
          service_tag: [
            'Confidence Boosts',
            'Adding Dimension'
          ],
          service_price: 100,
        },
        {
          service_name: 'One-tone Color',
          service_img: ServiceOne,
          service_tag: [
            'Confidence Boosts',
            'Adding Dimension'
          ],
          service_price: 100,
        },
        {
          service_name: 'One-tone Color',
          service_img: ServiceOne,
          service_tag: [
            'Confidence Boosts',
            'Adding Dimension'
          ],
          service_price: 100,
        }
      ],
      exp_rate: 4,
      talk_rate: 5,
      skill_rate: 4
    },
  ],
  days: [
    {label: 'Mon', status: 'active'}, 
    {label: 'Tue', status: 'active'}, 
    {label: 'Wed', status: 'active'}, 
    {label: 'Thurs', status: 'active'}, 
    {label: 'Fri', status: 'active'}, 
    {label: 'Sat', status: 'inactive'}, 
    {label:'Sun', status: 'inactive'} 
  ],
  service: [
    {
      service_id: 1,
      service_name: 'Hair Rebuilder Treatment + Director Restyle Hair Cut for 1 Pax',
      service_img: ServiceOne,
      service_tag: [
        'Hair Rebuilder Treatment',
        'Director Restyle Hair Cut'
      ],
      service_price: 68,
      service_time: 90,
      extra_service: 'Massage', //mayb array
      service_details: [
        { details_img: ServiceDetailsOne, 
          details_desc: 'In our consultation, we discuss and plan your unique hair color journey.' 
        },
        { details_img: ServiceDetailsTwo, 
          details_desc: 'In our consultation, we discuss and plan your unique hair color journey.' 
        }
      ]
    },
    {
      service_id: 2,
      service_name: 'Hair Coloring + Haircut + Hair Wash and Blow for 1 Pax',
      service_img: ServiceTwo,
      service_tag: [
        'Hair Cut',
        'Hair Wash and Blow'
      ],
      service_price: 58,
      service_time: 60,
      extra_service: 'Massage', //mayb array
      service_details: [
        { details_img: ServiceDetailsOne, 
          details_desc: 'In our consultation, we discuss and plan your unique hair color journey.' 
        },
        { details_img: ServiceDetailsTwo, 
          details_desc: 'In our consultation, we discuss and plan your unique hair color journey.' 
        }
      ]
    },
    {
      service_id: 3,
      service_name: 'Nano Thermic Hair Treatment for 1 Pax',
      service_img: ServiceThree,
      service_tag: [
        'Nano Thermic Hair Treatment',
        'Image Consultancy Service'
      ],
      service_price: 25,
      service_time: 30,
      extra_service: 'Massage', //mayb array
      service_details: [
        { details_img: ServiceDetailsOne, 
          details_desc: 'In our consultation, we discuss and plan your unique hair color journey.' 
        },
        { details_img: ServiceDetailsTwo, 
          details_desc: 'In our consultation, we discuss and plan your unique hair color journey.' 
        }
      ]
    },
    {
      service_id: 4,
      service_name: 'Hair Rebonding + Haircut + Hair Wash and Blow for 1 Pax',
      service_img: ServiceFour,
      service_tag: [
        'Hair Cut',
        'Hair Wash and Blow'
      ],
      service_price: 250,
      service_time: 120,
      extra_service: 'Massage', //mayb array
      service_details: [
        { details_img: ServiceDetailsOne, 
          details_desc: 'In our consultation, we discuss and plan your unique hair color journey.' 
        },
        { details_img: ServiceDetailsTwo, 
          details_desc: 'In our consultation, we discuss and plan your unique hair color journey.' 
        }
      ]
    },
    {
      service_id: 5,
      service_name: 'One-tone Color',
      service_img: ServiceFive,
      service_tag: [
        'Confidence Boosts',
        'Adding Dimension'
      ],
      service_price: 100,
      service_time: 90,
      extra_service: 'Massage', //mayb array
      service_details: [
        { details_img: ServiceDetailsOne, 
          details_desc: 'In our consultation, we discuss and plan your unique hair color journey.' 
        },
        { details_img: ServiceDetailsTwo, 
          details_desc: 'In our consultation, we discuss and plan your unique hair color journey.' 
        }
      ]
    }
  ],
  selected_services: [],
  selected_expert: ''
};

const dataSlice = createSlice({
  name: "store",
  initialState: initialData,
  reducers: {
    setToken: (state, action) => {
      state.token = localStorage.getItem("token");
    },
    setSelectedServices: (state, action) => {
      state.selected_services = action.payload;
    },
    setSelectedExpert: (state, action) => {
      state.selected_expert = action.payload;
    },
  },
  extraReducers: {
  },
});

export const { setToken, setSelectedServices, setSelectedExpert } = dataSlice.actions;

export default dataSlice.reducer;
