import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { MDBCard, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import useWindowDimensions from "../../../components/common/window-dimension";
import { useNavigate } from "react-router-dom";

export default function BranchToGo({ data }) {
    const navigate = useNavigate();
    const { width } = useWindowDimensions();

    return (
        <div className="branch-to-go">
          <div className="branch-info">
            <div className="flex justify-between"> 
                <label>Kuala Lumpur - Midvalley</label>
                <a className="pointer" href={data.location} target="blank">
                    <Icon icon="ion:location" color="#8c91e5" width={24} height={24} />
                </a>
            </div>
            <hr />
            <label>
                ATIVO PLAZA, Lot B-F-8, Block B, Jalan PJU 9/1, Damansara Avenue, Bandar Sri Damansara 52200 Kuala Lumpur
            </label>
            <label className="mt-2">Operation Hours: 10:00 - 18:00</label>
            </div>
        </div>
    )
}

