import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { MDBCard, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { closeStoreModal, openServiceModal } from "../../../redux/reducer/modalReducer";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedServices } from "../../../redux/reducer/storeReducer";

export default function OurService({ data, chooseServices, setChooseServices }) {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { selected_services } = useSelector((state) => state.store);

    const handleRedirect = (serviceId) => {
      navigate('/service')
      dispatch(closeStoreModal())
      dispatch(openServiceModal())
      dispatch(setSelectedServices([serviceId]))
    }

    const handleCheckboxChange = (serviceId) => {
        if (chooseServices?.length > 0 && chooseServices.includes(serviceId)) {
            setChooseServices(chooseServices.filter(id => id !== serviceId));
        } else {
            setChooseServices([...chooseServices, serviceId]);
        }
    }

    return (
        <div className="our-service">
          {data && data.length > 0 &&
           data.map((service, serviceIndex) => (
            <div className="service-row flex justify-between gap-1" key={serviceIndex}>
              <div className="service-frame pointer" onClick={() => handleRedirect(service.service_id)}>
                <LazyLoadImage
                  src={service.service_img}
                  alt="service-img"
                />
              </div>
              <div className="service-basic pointer" onClick={() => handleRedirect(service.service_id)}>
                <div className="_label --service_name">{service.service_name}</div>
                <div className="tag-row">
                  {service.service_tag && service.service_tag.length > 0 &&
                  service.service_tag.map((tag, tagIndex) => (
                    <div className="service-tag" key={tagIndex}>{tag}</div>
                  ))
                  }
               </div>
               <div className="flex justify-between items-center">
                <div className="_label --service-price flex items-baseline gap-1">
                  <span>RM </span>{service.service_price}
                </div>
               </div>
              </div>
              <div className="service-input flex items-center"> 
              {/* style={{ marginBottom: '2.6rem' }} */}
                <input
                  name=""
                  type="checkbox"
                  className="pointer"
                  onChange={() => handleCheckboxChange(service.service_id)}
                  checked={chooseServices?.length > 0 && chooseServices.includes(service.service_id)}
                />
              </div>
            </div>
           ))
          }
        </div>
    )
}