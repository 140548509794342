import { MDBBtn, MDBContainer, MDBModalBody } from "mdb-react-ui-kit";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import ServiceInfo from "./component/service.info";
import Button from "../../components/element/button";
import NewSection from "../../components/element/new.section";
import ServiceDetails from "./component/service.details";
import { useEffect, useRef, useState } from "react";
import scrollToTop from "../../helpers/scroll-to-top";
import { useNavigate } from "react-router-dom";
import FullModal from "../../components/modal/full.modal.box";
import { closeServiceModal, closeStoreModal, openBookingModal, openStoreModal } from "../../redux/reducer/modalReducer";
import useWindowDimensions from "../../components/common/window-dimension";
import ModalHeader from "../../components/header/modal-header";

export default function Service() {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const modalRef = useRef(null);

    const { width } = useWindowDimensions();
    const [ top, setTop ] = useState();
    const { isOpenServiceModal } = useSelector((state) => state.modal);
    const { store, expert, service, days, selected_services } = useSelector((state) => state.store);

    const backButton = () => {
        navigate('/')
        dispatch(closeServiceModal())
        dispatch(openStoreModal())
    }

    const handleRedirect = () => {
        navigate('/booking')
        dispatch(closeServiceModal())
        dispatch(openBookingModal())
    }

    useEffect(() => {
      setTop(true);
    }, [isOpenServiceModal]);
      
    useEffect(() => {
      if (top == true && modalRef.current) {
        scrollToTop(modalRef.current);
        setTop(false);
      }
    }, [top]);

    return (
        <>
            <FullModal
            centered
            scrollable
            staticBackdrop={true}
            show={isOpenServiceModal}
            backButton={backButton}
            screenSize={width >= 991 ? "xl" : "fullscreen-xl-down"}
            content={
                <>
                    <ModalHeader title="Service Details" backButton={backButton} backButtonNoAnimation={backButton} />
                      <MDBModalBody ref={modalRef}>
                        <MDBContainer className="service-container"> 
                        <ServiceInfo info={selected_services?.length > 0 && service.find((item) => item.service_id === selected_services[0])} />
                        <NewSection className="mt-3 ps-1" >Service Details</NewSection>
                        {selected_services?.length > 0 && service.find((item) => item.service_id === selected_services[0]).service_details?.length > 0 && service.find((item) => item.service_id === selected_services[0]).service_details.map((details, detailsIndex) => (
                            <ServiceDetails details={details} index={detailsIndex} />
                        ))}
                        <div className="button-line" />
                        <div className="mt-1 button-row"> 
                            <div />
                            <Button name="Booking" variant="solid" onClick={() => handleRedirect()}/>
                        </div>
                        </MDBContainer>      
                    </MDBModalBody>              
                </>
            }
            />
        </>
    )
}