//lib
import { MDBBtn, MDBCol, MDBRow } from "mdb-react-ui-kit";

export default function Button({ label, name, type, variant, disabled, className, onClick}) {
  return (
    <div className={`element _button ${className}`}>
      {/* {label && (
        <MDBRow>
          <label htmlFor={name} className="form-label">
            {label}
          </label>
        </MDBRow>
      )} */}
      <MDBBtn
        type={type}
        className={ 
            variant === "solid" ? 
                "solid-button" 
            : variant === "outline" ? 
                "outline-button"
            : ""
        }
        disabled={disabled ? disabled : false}
        onClick={onClick}
      >
        {name}
      </MDBBtn>
    </div>
  );
}
