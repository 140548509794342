import { configureStore } from "@reduxjs/toolkit";

//reducer
import commonReducer from "./reducer/commonReducer";
import settingReducer from "./reducer/settingReducer";
import storeReducer from "./reducer/storeReducer";
import modalReducer from "./reducer/modalReducer";
import bookingReducer from "./reducer/bookingReducer";

const reducer = {
    common: commonReducer,
    setting: settingReducer,
    store: storeReducer,
    modal : modalReducer,
    booking : bookingReducer,
}

const store = configureStore({
    reducer: reducer,
    devTools: process.env.REACT_APP_DEV_TOOL,
})

export default store