import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { MDBCard, MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import useWindowDimensions from "../../../components/common/window-dimension";
import NewSection from "../../../components/element/new.section";
import OurService from "../../Service/component/our.service";

export default function ServiceDetails({ details, index }) {
    const { width } = useWindowDimensions();

    return (
        <div className="service-details" key={index}>
            <div className="details-frame">
                <LazyLoadImage
                    alt="expert-img"
                    src={details.details_img}
                />
            </div>
            <div className="details-row flex align-items-start justify-between">
                <div className="_label --service_details">{details.details_desc}</div>

                <Icon icon="bxs:caret-up-square" color="#888DAA" width={20} height={20} className="mt-1" />
            </div>
        </div>
    )
}