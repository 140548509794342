import { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/element/button";
import useWindowDimensions from "../../components/common/window-dimension";
import { closeConfirmationModal, openBookingModal, openSuccessModal } from "../../redux/reducer/modalReducer";
import ModalHeader from "../../components/header/modal-header";
import { useNavigate } from "react-router-dom";
import HalfModal from "../../components/modal/half.modal.box";
import BookingDetails from "./components/booking.details";
import { setBookingDetails, setContactDetails } from "../../redux/reducer/bookingReducer";
import { MDBModalBody } from "mdb-react-ui-kit";
import scrollToTop from "../../helpers/scroll-to-top";

export default function Confirmation({ totalPrice }) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const modalRef = useRef(null)
    
    const { width } = useWindowDimensions();
    const { isOpenConfirmationModal } = useSelector((state) => state.modal);
    const { booking_details } = useSelector((state) => state.booking);

    const [ name, setName ] = useState('');
    const [ phone, setPhone ] = useState('');
    const [ top, setTop ] = useState();

    const handleRedirect = () => {
        dispatch(closeConfirmationModal())
        dispatch(setContactDetails({ name: name, phone: phone }))
        dispatch(openSuccessModal())
    }

    const backButton = () => {
        navigate('/booking')
        dispatch(closeConfirmationModal())
        dispatch(openBookingModal())
    }

    useEffect(() => {
      setTop(true);
    }, [isOpenConfirmationModal]);
        
    useEffect(() => {
      if (top == true && modalRef.current) {
        scrollToTop(modalRef.current);
        setTop(false);
      }
    }, [top]);

    return (
        <>
            <HalfModal
                centered
                scrollable
                staticBackdrop={true}
                show={isOpenConfirmationModal}
                desktopModal={width >= 991 ? true : false}
                screenSize={width >= 991 ? "xl" : ""}
                content={
                    <>
                        <ModalHeader title="Booking Confirmation" backButton={backButton} backButtonNoAnimation={backButton} />
                          <MDBModalBody ref={modalRef}>
                            <div className="confirmation-container"> 
                                <BookingDetails data={booking_details} setName={setName} setPhone={setPhone} />
                                <div className="mt-5 button-line" />
                                <div className="mt-1 button-row"> 
                                    <div className={`d-grid ${ width > 991 ? 'gap-2' : 'gap-1' }`}>
                                        <label  className="_label --selected-service" >{booking_details.length} services selected: </label>
                                        <label className="_label --main_price flex align-items-baseline gap-1"><span>RM</span> {totalPrice} </label>
                                    </div>
                                    <Button name="Booking" variant="solid" onClick={() => handleRedirect()} />
                                </div>
                            </div>
                        </MDBModalBody>
                    </>
                }
            />
        </>
    );
}