import { MDBBtn, MDBContainer } from "mdb-react-ui-kit";
import { useDispatch, useSelector } from "react-redux";
import StoreHeader from "./component/store.header";
import StoreInfo from "./component/store.info";
import NewSection from "../../components/element/new.section";
import OurExpert from "./component/our.expert";
import Button from "../../components/element/button";
import ExpertInfo from "../Expert/component/expert.info";
import OurService from "../Service/component/our.service";
import FullModal from "../../components/modal/full.modal.box";
import useWindowDimensions from "../../components/common/window-dimension";
import { closeStoreModal, openBookingModal, openConfirmationModal, openExpertModal, openServiceModal } from "../../redux/reducer/modalReducer";
import { useNavigate, useParams } from "react-router-dom";
import Expert from "../Expert";
import { useEffect, useState } from "react";
import Booking from "../Booking";
import Confirmation from "../Confirmation";
import Service from "../Service";
import Success from "../../components/modal/success.modal.box";
import { setSelectedServices } from "../../redux/reducer/storeReducer";
import scrollToTop from "../../helpers/scroll-to-top";

export default function Store() {
    const params = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { width } = useWindowDimensions();
    const { isOpenStoreModal, isOpenExpertModal, isOpenServiceModal, isOpenBookingModal, isOpenConfirmationModal, isOpenSuccessModal } = useSelector((state) => state.modal);
    const { store, expert, service, days, selected_services } = useSelector((state) => state.store);

    const [ chooseServices, setChooseServices ] = useState(selected_services);

    const totalServices = chooseServices.map(serviceId => {
        const selectedService = service.find(s => s.service_id === serviceId);
        return selectedService ? selectedService.service_price : 0;
    });

    const totalPrice = totalServices.reduce((acc, price) => acc + price, 0);

    // const autoOpenModal = {
    //     expert: openExpertModal,
    //     service: openServiceModal,
    //     // booking: openBookingModal,
    //     confirmation: openConfirmationModal,
    // };

    const handleRedirect = () => {
        navigate('/booking')
        dispatch(closeStoreModal())
        dispatch(openBookingModal());
        dispatch(setSelectedServices(chooseServices))
    }

    // useEffect(() => {
    //     if (params.state) {
    //         dispatch(autoOpenModal[params.state]);
    //     }
    // }, [params.state]);

    useEffect(() => {
        if (selected_services.length === 0) {
            setChooseServices([])
        }
    }, [selected_services]);

    useEffect(() => {
        scrollToTop()
    }, [])
        
    return (
        <>
            <MDBContainer className="store-container"> 
                <StoreHeader data={store} />
                <StoreInfo data={store}/>
                <NewSection>Our Experts</NewSection>
                <OurExpert data={expert} />
                <NewSection>Our Services</NewSection>
                <OurService data={service} chooseServices={chooseServices} setChooseServices={setChooseServices} />
                <div className="button-line" />
                <div className="mt-1 button-row"> 
                <div className={`d-grid ${ width > 991 ? 'gap-2' : 'gap-1' }`}>
                  {chooseServices.length > 0 && 
                    <>
                      <label className="_label --selected_service">{chooseServices.length} services selected: </label>
                      <label className="_label --main_price flex align-items-baseline gap-1"><span>RM</span> {totalPrice} </label>
                    </>
                  }
                  </div>
                  <Button name="Booking" variant="solid" onClick={() => handleRedirect()} />
                </div>

            </MDBContainer>
            {isOpenExpertModal && <Expert />}
            {isOpenServiceModal && <Service />}
            {isOpenBookingModal && <Booking totalPrice={totalPrice} />}
            {isOpenConfirmationModal && <Confirmation totalPrice={totalPrice} />}
            {isOpenSuccessModal && <Success />}
        </>
    );
}