import { useEffect, useRef, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { MDBBtn, MDBCard, MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import useWindowDimensions from "../../../components/common/window-dimension";
import NewSection from "../../../components/element/new.section";
import OurService from "../../Service/component/our.service";
import { ServiceOne } from "../../../assets/images";
import HandlingExperts from "./handling.experts";
import BranchToGo from "./branch.to.go";
import AvailableTime from "./available.time";
import { useDispatch, useSelector } from "react-redux";
import { setExpertSelections, setTimeSelections } from "../../../redux/reducer/bookingReducer";

export default function ScheduleBooking({ expert, date, setIsLastSlide, setNextSlide, nextSlide }) {
    const { width } = useWindowDimensions();
    const dispatch = useDispatch();

    const { selected_services, service, store } = useSelector((state) => state.store);
    const { expertSelections, timeSelections } = useSelector((state) => state.booking);

    const [progressWidth, setProgressWidth] = useState(0);

    const handleExpertSelection = (serviceId, expert) => {
        dispatch(setExpertSelections({
            ...expertSelections,
            [serviceId]: expert,
        }));
    };

    const handleTimeSelection = (serviceId, time) => {
        dispatch(setTimeSelections({
            ...timeSelections,
            [serviceId]: time,
        }));
    };

    const selectedServiceDetails = selected_services.map(selectedServiceId => {
        const matchingService = service.find(s => s.service_id === selectedServiceId);
        const selectedExpert = expertSelections[selectedServiceId] || "";
        const selectedTime = timeSelections[selectedServiceId] || "";
        return {
            ...matchingService,
            selectedExpert,
            selectedTime,
        };
    });

    const totalSlides = selectedServiceDetails.length;
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

    const swiperRef = useRef(null);

    const handlePreviousSlide = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    const handleNextSlide = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slideNext();
        }
    };

    const handleProgressClick = (clickedIndex) => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slideTo(clickedIndex);
        }
    };
    
    useEffect(() => {
        if(nextSlide) {
            handleNextSlide()
            setNextSlide(false)
        }
    }, [nextSlide])

    useEffect(() => {
        if (swiperRef.current) {
            const newIndex = swiperRef.current.swiper.activeIndex + 1;

            if (newIndex === totalSlides) {
                setIsLastSlide(true);
            } else {
                setIsLastSlide(false);
            }
        }
    }, [swiperRef.current?.swiper?.activeIndex])

    useEffect(() => {
        let updatedSelection = { ...timeSelections };
    
        selected_services.forEach((serviceId) => {
            updatedSelection = {
                ...updatedSelection,
                [serviceId]: `Today 07-17, 11:00`
            };
        });
    
        dispatch(setTimeSelections(updatedSelection));
    
    }, [selected_services]);

    return (
        <div className="schedule-info mb-5">
            <div className="flex justify-between items-center mt-2 pe-3">
                <label className="_label --fill_up ms-3">Please fill up {totalSlides > 1 ? totalSlides : 'the'} selected services booking details </label>
                {totalSlides > 1 &&
                    <div className="flex" style={{ borderRadius: '20px', boxShadow: '0 2px 7px #a3a5cf' }}>
                        <div onClick={handlePreviousSlide} className="pointer">
                            <MDBBtn 
                                className="previous-btn" 
                                type="button"
                            >
                                <label><Icon icon="icon-park-outline:left" className="pointer" /></label>
                            </MDBBtn>
                        </div>

                        <div onClick={handleNextSlide} className="pointer">
                            <MDBBtn                             
                                className="next-btn" 
                                type="button"
                            >
                            <label><Icon icon="icon-park-outline:right" className="pointer" /></label>
                            </MDBBtn>
                        </div>
                    </div>
                }
            </div>
            <div style={{ height: '1em' }}>
            <div className="progress-bar flex justify-center mb-2" style={{ marginTop: '1.8em' }}>
                    <div
                        className="progress-bar-fill"
                        style={{ width: `${((currentSlideIndex + 1) / totalSlides) * 100}%`, transition: 'width 0.5s ease'}}
                    />
                    {[...Array(totalSlides).keys()].map((index) => (
                        <div 
                            key={index} 
                            data={index}
                            style={{ width: `${((index + 1) / totalSlides) * 88}%`, position: 'absolute', textAlign: 'end'}}
                           
                        >
                            <label className="pointer" style={{ background: 'white', height: '2em', width: index === totalSlides - 1 ? '3.5em' : '2em', borderRadius: index === totalSlides - 1 ? '20px' : '20px', border: '2px solid #8C91E5', textAlign: 'center', fontSize: '15px', position: 'relative', zIndex: 90 }}
                             onClick={(e) =>{handleProgressClick(index)}}
                            >
                                {index === totalSlides - 1 ? 'END' : index + 1}
                            </label>
                        </div>
                    ))}
            </div>
            </div>
            <Swiper
                ref={swiperRef}
                allowTouchMove={false}
                onSlideChange={(swiper) => setCurrentSlideIndex(swiper.activeIndex)}
            >
            {selectedServiceDetails.map((selectedService, serviceIndex) => {
                return (
                    <SwiperSlide key={serviceIndex} className="">
                        <div>
                            <NewSection>Selected Services ({serviceIndex + 1})</NewSection>
                            <div className="selected-service gap-3 mt-2">
                                <div className="selected-frame col-4">
                                    <LazyLoadImage
                                        alt="selected-img"
                                        src={selectedService.service_img}
                                    />
                                </div>
                                <div className="selected-basic d-grid">
                                    <label className="_label --selected_service">{selectedService.service_name}</label>
                                    <label className="_label --service_price">
                                        <span className="">RM</span> {selectedService.service_price}
                                    </label>
                                </div>
                            </div>
                            <NewSection className="mt-4" icon="pepicons-print:person" color="#888DAA" >Select Expert</NewSection>
                            <HandlingExperts data={expert} selection={selectedService.selectedExpert} serviceId={selectedService.service_id} setSelect={handleExpertSelection} />
                            <NewSection className="pt-0" icon="uim:clock" color="#888DAA" >Select Time</NewSection>
                            <AvailableTime data={date} selection={selectedService.selectedTime} serviceId={selectedService.service_id} setSelect={handleTimeSelection} />
                        </div>
                    </SwiperSlide>
                );
            })}
            </Swiper>
            <NewSection>Our Location</NewSection>
            <BranchToGo data={store} />
        </div>
    );
}