import './scss/index.scss';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

//toast
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

//pages
import MainLayout from './components/layout/main-layout';
import Store from './pages/Store';
import Expert from './pages/Expert';
import Service from './pages/Service';
import Booking from './pages/Booking';
import Confirmation from './pages/Confirmation';
import { useEffect } from 'react';

//toast



function App() {
  function updateMetaThemeColor() {
    document
      .querySelector("meta[name='theme-color']")
      .setAttribute("content", "#8C91E5");
  }
  
  useEffect(() => {
    updateMetaThemeColor();
  }, []);

  return (
    <BrowserRouter>
    <Routes>
      <Route 
        path="/"
        element={
          <MainLayout>
            <Store />
          </MainLayout>
        } 
      />
      <Route 
        path="/:state" 
        element={
          <MainLayout>
            <Store />
          </MainLayout>
        } 
      />
    </Routes>
    <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
