import { useEffect, useRef, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { MDBBtn, MDBCol, MDBContainer, MDBModalBody, MDBRow } from "mdb-react-ui-kit";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/element/button";
import useWindowDimensions from "../../components/common/window-dimension";
import { closeAllModal, closeBookingModal, closeConfirmationModal, closeSuccessModal, openConfirmationModal, openStoreModal } from "../../redux/reducer/modalReducer";
import FullModal from "../../components/modal/full.modal.box";
import ModalHeader from "../../components/header/modal-header";
import { useNavigate } from "react-router-dom";
import { setBookingDetails, setContactDetails } from "../../redux/reducer/bookingReducer";
import { Icon } from "@iconify/react";
import { setSelectedServices } from "../../redux/reducer/storeReducer";
import scrollToTop from "../../helpers/scroll-to-top";

export default function Success() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const modalRef = useRef(null);

    const { isOpenSuccessModal } = useSelector((state) => state.modal);
    const { booking_details, contact_details } = useSelector((state) => state.booking);

    const { width } = useWindowDimensions();

    const [ top, setTop ] = useState();

    const backButton = () => {
        navigate('/')
        dispatch(setBookingDetails([]))
        dispatch(setContactDetails({}))
        dispatch(setSelectedServices([]))
        dispatch(closeAllModal())
    }

    const handleRedirect = () => {
        navigate('/')
        dispatch(setBookingDetails([]))
        dispatch(setContactDetails({}))
        dispatch(setSelectedServices([]))
        dispatch(closeAllModal())
    }

    useEffect(() => {
        setTop(true);
      }, [isOpenSuccessModal]);
      
      useEffect(() => {
        if (top == true && modalRef.current) {
          scrollToTop(modalRef.current);
          setTop(false);
        }
      }, [top]);
  

    return (
        <>
            <FullModal
                centered
                scrollable
                staticBackdrop={true}
                show={isOpenSuccessModal}
                backButton={backButton}
                screenSize={width >= 991 ? "xl" : "fullscreen-xl-down"}
                contentClassName="success-modal playing"
                bodyClassName="h-auto"
                content={
                    <MDBModalBody ref={modalRef}>
                        <section class="pattern">
                        <MDBContainer className="success-container" style={{ height: `${600 + (booking_details.length - 1) * (width > 991 ? 200 : 160) }px` }}>
                            <h4 className="_label --success_title">Success Booking !</h4>
                            <div className="flex justify-center mt-4 gap-3">
                                <h6 className="_label --phone_no">{contact_details.phone}</h6>
                                <Icon icon="icon-park-outline:success" color="#7FA677" width="30" />
                            </div>
                            {booking_details?.length > 0 && booking_details.map((booking, bookingIndex) => (
                            <div className={ bookingIndex - 1 < 0 ? "mt-4" : "mt-5"}>
                                <div>Booked Service ({bookingIndex + 1})</div>
                                <MDBRow className="mt-2">
                                    <MDBCol className="_label --col_label">
                                        Booking Name
                                    </MDBCol>
                                    <MDBCol className="text-end col-7 col-md-8 col-lg-8 _label --col_data">
                                        {booking.selected_service_name}
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="mt-2">
                                    <MDBCol className="_label --col_label">
                                        Booking Date
                                    </MDBCol>
                                    <MDBCol className="text-end col-7 col-md-8 col-lg-8 _label --col_data">
                                        {booking.selected_booking_time}
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="mt-2">
                                    <MDBCol className="_label --col_label">
                                        Expert Request
                                    </MDBCol>
                                    <MDBCol className="text-end col-7 col-md-8 col-lg-8 _label --col_data">
                                        Fai Lai
                                    </MDBCol>
                                </MDBRow>
                            </div>
                            ))}
                            <div className="mt-5" >Location</div>
                            <MDBRow className="mt-2 ms-0 _label --col_label">
                                ATIVO PLAZA, Lot B-F-8, Block B, Jalan PJU 9/1, Damansara Avenue, Bandar Sri Damansara 52200 Kuala Lumpur
                            </MDBRow>
                        </MDBContainer>
                        </section>
                        <div className="button-div flex justify-content-center">
                            <MDBBtn className="success-btn" onClick={() => handleRedirect()}>Done</MDBBtn> 
                        </div>
                    </MDBModalBody>
                }
            />
        </>
    );
}