import { useState } from "react";

import { MDBCard, MDBCol, MDBContainer, MDBInput, MDBRow } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import useWindowDimensions from "../../../components/common/window-dimension";
import { useNavigate } from "react-router-dom";
import Text from "../../../components/element/text";
import { Formik, Form } from "formik";
import { LazyLoadImage } from "react-lazy-load-image-component";
import NewSection from "../../../components/element/new.section";

export default function BookingDetails({ data, setName, setPhone }) {
    const navigate = useNavigate();
    const { width } = useWindowDimensions();

    return (
        <Formik
            // initialValues={initialValues}
            // validationSchema={formSchema}
            // enableReinitialize={true}
            // validateOnChange={true}
            // onSubmit={(values, { setFieldError, resetForm }) => {
            // handleSubmit({ values, setFieldError });
            // resetForm({ values: values });
            // }}
        >
        {({
          setFieldValue,
          setFieldError,
          isSubmitting,
          isValid,
          values,
          errors,
        }) => (
          <Form>
            <div className="booking-details">
                {data?.length > 0 && data.map((booked, bookedIndex) => (
                    <>
                    <NewSection className={bookedIndex - 1 < 0 ? 'pt-0' : ''} >Selected Services ({bookedIndex + 1})</NewSection>
                    <div className="selected-service mt-2">
                        <div className="flex gap-3">
                            <div className="selected-frame col-2">
                                <LazyLoadImage
                                    alt="selected-img"
                                    src={booked.selected_service_image}
                                />
                            </div>
                            <div className="selected-basic d-grid">
                                <label className="_label --selected_service">{booked.selected_service_name}</label>
                                <label className="_label --service_price">
                                    <span className="">RM</span> {booked.selected_service_price}
                                </label>
                            </div>
                        </div>
                        <MDBRow className="booking-row text-center mt-3">
                            <MDBCol className="d-grid gap-2"> 
                                <label className="_label">Booking Date</label>
                                <label className="_label --details-info">{booked.selected_booking_time}</label>
                            </MDBCol>
                            <MDBCol className="d-grid gap-2"> 
                                <label className="_label">Location</label>
                                <label className="_label --details-info">Kuala Lumpur</label>
                            </MDBCol>
                            <MDBCol className="d-grid gap-2"> 
                                <label className="_label">Expert Request</label>
                                <label className="_label --details-info">{booked.selected_expert_name}</label>
                            </MDBCol>
                        </MDBRow>
                    </div>

                    <br />
                    </>
                ))}
                <div className="booking-input">
                <MDBRow className="mt-4">
                    <Text
                    // name="name"
                    maxLength={20}
                    placeholder={
                        // values.name !== null ? "Please enter your name" : ""
                        "Please enter your name"
                    }
                    as={MDBInput}
                    className="mdb-field"
                    onChange={(e) => setName(e.target.value)}
                    />
                </MDBRow>
                <MDBRow className="mt-2">
                    <Text
                    // name="phone_no"
                    maxLength={20}
                    placeholder={
                        // values.name !== null ? "Please enter your name" : ""
                        "Please enter your phone number"
                    }
                    as={MDBInput}
                    className="mdb-field"
                    onChange={(e) => setPhone(e.target.value)}
                    />
                </MDBRow>
                {/* <input 
                /> */}
                </div>
            </div>
          </Form>
        )}
        </Formik>
    )
}
