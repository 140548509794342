import { LazyLoadImage } from "react-lazy-load-image-component";

export default function StoreHeader({ data }) {

    return (
        <div className="store-header flex items-center gap-2">
            <div className="logo-frame items-center">
                <LazyLoadImage
                    alt="store-logo"
                    src={data.store_logo}
                />
            </div>
            <div className="d-grid">
                <label className="_label --store_name">{data.store_name}</label>
                <label className="_label --store_reg_no">{data.store_reg_no}</label>
            </div>
        </div>
    )
}