import { createSlice } from "@reduxjs/toolkit";

export const initialData = {
};

const dataSlice = createSlice({
  name: "common",
  initialState: initialData,
  reducers: {
    setToken: (state, action) => {
      state.token = localStorage.getItem("token");
    },
  },
  extraReducers: {
  },
});

export const { setToken} = dataSlice.actions;

export default dataSlice.reducer;

